
import { Component, Vue } from "vue-property-decorator";
import Sportevent from "../../models/sportevent/Sportevent";
import SporteventService from "../../services/sportevent/SporteventService";
import Multiselect from "vue-multiselect";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";

@Component({
  components: {
    Multiselect,
  },
})
export default class CopySporteventCompetitionsComponent extends Vue {
  public loading = true;
  public sportevents: Sportevent[] = [];
  public copyFromSportevent: Sportevent = new Sportevent();

  public inprogess = false;

  async mounted(): Promise<void> {
    this.sportevents = await SporteventService.getAll();
    this.sportevents = this.sportevents.filter(
      (s: Sportevent) =>
        s.id !== this.$store.state.localstore_sportevent.sportevent.id
    );
    this.loading = false;
  }
  
  async copyfrom(): Promise<void> {
    this.inprogess = true;
    if (this.copyFromSportevent.id !== undefined) {
      await SporteventService.copyCompetitions(
        this.$store.state.localstore_sportevent.sportevent.id,
        this.copyFromSportevent.id
      );
      await SporteventCompetitionService.getAll(true);
      this.inprogess = false;
      this.$router.back();
    }
    this.inprogess = false;
  }
}
